export class StickyBar {
    private static _instance: StickyBar;
    private static _context: HTMLElement = document.querySelector("#sticky-bar");
    private _container: HTMLElement;
    private _countdownInterval: NodeJS.Timer;

    private constructor() {
        this.initialize();
    }

    public initialize() {
        if (!StickyBar._context)
            return;

        this._container = StickyBar._context.querySelector("#main-container");
        const _dropdowns = StickyBar._context.querySelectorAll("#dropdown-arrow");

        this.updateCountdown();

        // Update the countdown every second
        this._countdownInterval = setInterval(this.updateCountdown, 1000);

        document.body.addEventListener("click", (event) => {
            const target = event.target as HTMLElement;

            if (target && target.id == "dropdown-arrow") {
                this.toggleDropdown(target);
            }
        });

        let _sliderContainer = this._container.querySelectorAll(".slider-container");
        const _firstSlide = _sliderContainer[0] as HTMLElement;
        const fullLength = _firstSlide.offsetWidth * _sliderContainer.length;
        let count = 0;

        if (_sliderContainer.length > 1) {
            setInterval(() => {
                let _barsText = this._container.querySelectorAll("#bar-text");

                if ((_barsText[0] as HTMLElement).style.webkitLineClamp !== "10") {

                    if (_sliderContainer.length > 1) {
                        // Apply the transformation
                        _sliderContainer.forEach((slide: HTMLElement) => {
                            slide.style.transform = `translateX(${(-count - 1) * 100}%)`;
                        });
                        
                    }

                    let clonedItem = _sliderContainer[count].cloneNode(true) as HTMLElement;
                    this._container.append(clonedItem);
                    _sliderContainer = this._container.querySelectorAll(".slider-container");
                    this.updateCountdown();
                    count++;
                }
            }, 5000);
        }
    }

    private toggleDropdown = ((dropdown: HTMLElement) => {
        let _barsText = this._container.querySelectorAll("#bar-text");

        _barsText.forEach((text: HTMLElement) => {
            if (text.style.webkitLineClamp === "1" || text.style.webkitLineClamp === "") {
                text.style.webkitLineClamp = "10";
                dropdown.style.transform = "rotate(180deg)";
            } else {
                text.style.webkitLineClamp = "1";
                dropdown.style.transform = "unset";
            }
        });
    }).bind(this);

    // Function to update the countdown every second
    private updateCountdown = (): void => {
        let _countdownContainer = document.querySelectorAll("#countdown-timer");

        _countdownContainer.forEach((countdown: HTMLElement) => {
            let _countdownDate: number;

            if (_countdownContainer.length > 0) {
                _countdownDate = new Date(countdown.getAttribute("data-countdownDate")).getTime();
            }

            let offset = new Date().getTimezoneOffset();
            const now: number = new Date().getTime();

            // Calculate the remaining time
            const distance: number = _countdownDate - now + (offset * 60000);

            // Time calculations for days, hours, minutes, and seconds
            const days: number = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours: number = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes: number = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds: number = Math.floor((distance % (1000 * 60)) / 1000);

            const daysElement = countdown.querySelector("#days");
            const hoursElement = countdown.querySelector("#hours");
            const minutesElement = countdown.querySelector("#minutes");
            const secondsElement = countdown.querySelector("#seconds");

            if (daysElement && hoursElement && minutesElement && secondsElement) {
                daysElement.innerHTML = `${days}d`;
                hoursElement.innerHTML = `${hours}h`;
                minutesElement.innerHTML = `${minutes}m`;
                secondsElement.innerHTML = `${seconds}s`;
            }

            // This should never happen
            if (distance < 0) {
                clearInterval(this._countdownInterval);
                const countdownElement = document.getElementById("countdown");
                if (countdownElement) {
                    countdownElement.style.display = "none";
                }
            }
        });
    };

    public static get Instance() {
        return this._context && (this._instance || (this._instance = new this()));
    }
}

export const stickyBar = StickyBar.Instance;

if (stickyBar) {
    (window as any).stickyBar = stickyBar;
}